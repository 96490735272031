import { AfterViewChecked, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MatStepperNext } from "@angular/material/stepper";
import { ApiService } from "../../../../api.service";
import { Question } from "../../../../api/api.interfaces";
import { NgIf } from "@angular/common";
import { FormFieldComponent } from "../form-field/form-field.component";
import { SpinnerComponent } from "../../../spinner/spinner.component";
import { Participant } from '@models/participant.model';

@Component({
  selector: 'questionnaire-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatButton,
    MatStepperNext,
    NgIf,
    FormFieldComponent,
    SpinnerComponent
  ],
  templateUrl: './questionnaire-form.component.html',
})
export class QuestionnaireFormComponent implements AfterViewChecked {
  @Input() disabled: boolean = false

  participant: Participant;
  formId: number;
  questions: Question[]
  type: string

  loading: boolean = true
  formGroup = new FormGroup({})

  constructor(
    private apiService: ApiService,
    private changeRef: ChangeDetectorRef
  ) {
  }

  ngAfterViewChecked(): void {
    if ((this.participant != undefined && this.participant.status.status == "Screened out") || this.disabled) {
      this.formGroup.disable()
      this.changeRef.detectChanges()
    }
  }

  refresh(participant: Participant, type: string) {
    this.loading = true
    this.type = type
    this.participant = participant
    this.formGroup = new FormGroup({})
    this.queryFormQuestions()
  }

  isValid(): boolean {
    const valid = this.formGroup.valid
    if (!valid) {
      this.formGroup.markAllAsTouched()
    }
    return valid
  }

  disable(): void {
    this.disabled = true
    // this.formGroup.disable()
  }

  getFormId(): number {
    return this.formId
  }

  getResult() {
    let result = []
    this.questions.forEach((question: Question) => {
      result.push(this.getQuestionResult(question))
      question.extras?.forEach(extra => {
        const extraResult = this.getQuestionResult(extra)
        if (extraResult != undefined) {
          result.push(extraResult)
        }
      })
    })
    return result
  }

  private getQuestionResult(question: Question) {
    let answer = null
    const value = this.formGroup.get("question" + question.id).value
    if (value == undefined) {
      return undefined
    }
    if (question.type == "CHECKBOX") {
      answer = []
      question.options.forEach((option) => {
        if (value["option" + option.id]) {
          answer.push(option.id)
        }
      })
    } else {
      answer = value.toString()
    }
    return {
      id: question.id,
      answer: answer,
    }
  }

  private queryFormQuestions() {
    this.apiService.listStudyForms(this.participant.studySite.study.id, this.type).subscribe(response => {
      const formId = response.data[0].form.id
      this.formId = formId
      this.apiService.listFormQuestions(formId).subscribe(response2 => {
        this.questions = response2.data.questions
        this.queryParticipantFormResults(this.participant.id, formId, this.questions)
      })
    })
  }

  private queryParticipantFormResults(participantId: number, formId: number, questions: Question[]) {
    this.apiService.getParticipantFormResults(participantId, formId).subscribe(response => {
      if (response.data != undefined) {
        response.data.forEach(formResult => {
          const question = this.searchQuestion(questions, formResult.question.id)
          if (question != null) {
            question.answer = formResult.answer
          }
        })
      }
      this.loading = false
      this.changeRef.detectChanges()
    })
  }

  private searchQuestion(questions: Question[], id: number): Question | null {
    let selectedQuestion = null
    questions.forEach(question => {
      if (question.id == id) {
        selectedQuestion = question
      }
      question.extras?.forEach(extra => {
        if (extra.id == id) {
          selectedQuestion = extra
        }
      })
    })
    return selectedQuestion
  }
}
