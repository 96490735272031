import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {MatButton} from "@angular/material/button";
import {MatStepperNext} from "@angular/material/stepper";
import {NgIf} from "@angular/common";
import {Question} from "../../../../api/api.interfaces";

@Component({
    selector: 'form-field',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatButton,
        MatStepperNext,
        NgIf
    ],
    templateUrl: './form-field.component.html',
    styleUrl: './form-field.component.scss'
})
export class FormFieldComponent implements OnInit, OnChanges {
    @Input() disabled: boolean = false
    @Input() required: boolean = true
    @Input() question: Question
    @Input() questions!: Question[];
    @Input() parentFormGroup: FormGroup
    @Input() defaultValue: any
    @Input() orderNumber: number
    @Output() parentFormGroupChange = new EventEmitter<FormGroup>()

    formControl = new FormControl()
    formGroup = new FormGroup({})

    ngOnInit(): void {
        this.formControl.addValidators((control: FormControl) => {
            if (!this.isVisible || !this.required) {
                return null
            }
            if (control.value == "" || control.value == null) {
                return {required: true}
            }
            return null
        })
        this.formGroup.addValidators((control: FormGroup) => {
            if (!this.isVisible || this.question.type != "CHECKBOX" || !this.required) {
                return null
            }
            let selected = Object.keys(control.value).filter(element => control.value[element] === true)
            if (selected.length > 0) {
                return null
            }
            return {required: true}
        })
        if (this.question.type == "CHECKBOX") {
            this.question.options.forEach((option) => {
                this.formGroup.addControl('option' + option.id, new FormControl())
            })
            this.parentFormGroup.addControl('question' + this.question.id, this.formGroup)
        } else {
            this.parentFormGroup.addControl('question' + this.question.id, this.formControl)
        }
        if (this.disabled) {
            this.formControl.disable()
            this.formGroup.disable()
        }
        this.parentFormGroupChange.emit(this.parentFormGroup)
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["defaultValue"] != undefined) {
            const value = changes["defaultValue"].currentValue
            if (value != undefined) {
                if (this.question.type == "RADIO") {
                    this.formControl.setValue(value[0])
                }
                if (this.question.type == "CHECKBOX") {
                    value.forEach((option) => {
                        this.formGroup.get("option" + option).setValue(true)
                    })
                    this.formControl.setValue(value[0])
                }
                if (this.question.type != "RADIO" && this.question.type != "CHECKBOX") {
                    this.formControl.setValue(value)
                }
            }
        }
    }

    private getSelectedOption(id) {
        let selected = null
        this.question.options.forEach((option) => {
            if (option.id == id) {
                selected = option
            }
        })
        return selected
    }

    checkboxSelected(value) {
        const selectedValues = this.formGroup.value
        let uniqueValue = null
        Object.keys(selectedValues).forEach((key) => {
            if (selectedValues[key] == true) {
                const option = this.getSelectedOption(key.replace("option", ""))
                if (option.attributes != null && option.attributes.unique) {
                    uniqueValue = key
                }
            }
        })
        if (uniqueValue != null) {
            if (uniqueValue == "option" + value) {
                Object.keys(selectedValues).forEach((key) => {
                    if (selectedValues[key] == true && key != uniqueValue) {
                        this.formGroup.get(key).setValue(false)
                    }
                })
            } else {
                this.formGroup.get(uniqueValue).setValue(false)
            }

        }
        this.onValueChange()
    }

    onValueChange() {
        this.questions.forEach(question => {
            if (question.conditions.question == this.question.id) {
                this.parentFormGroup.get("question" + question.id).updateValueAndValidity()
            }
        })
    }

    getQuestionById(id: number) {
        let result = null
        this.questions?.forEach(question => {
            if (question.id == id) {
                result = question
            }
        })
        return result
    }

    get isVisible(): boolean {
        return this.checkCondition()
    }

    checkCondition(): boolean {
        if (this.question.conditions.question == undefined) {
            return true
        }
        const value = this.parentFormGroup.get("question" + this.question.conditions.question).value
        if (value === "" || value == null) {
            return false;
        }
        const linkedQuestion = this.getQuestionById(this.question.conditions.question)
        if (linkedQuestion == null) {
            return false
        }
        if (linkedQuestion.type == "RADIO" && isNaN(Number(value))) {
            return false;
        }

        let elementValue = value
        if (linkedQuestion.type == "RADIO") {
            elementValue = parseInt(value)
        }

        const condValue = parseInt(this.question.conditions.value)

        switch (this.question.conditions.op) {
            case 'lt':
                return (elementValue < condValue);
            case 'lte':
                return elementValue <= condValue;
            case 'eq':
                if (linkedQuestion.type == "RADIO") {
                    return elementValue == condValue;
                }
                let answer = []
                linkedQuestion.options.forEach((option) => {
                    if (value["option" + option.id]) {
                        answer.push(option.id)
                    }
                })
                return answer.includes(condValue)
            case 'ne':
                return elementValue != condValue;
            case 'gt':
                return elementValue > condValue;
            case 'gte':
                return elementValue >= condValue;
            default:
                return false;
        }
    }
}
