<div [hidden]="!isVisible">
    <h6 class="mt-4 mb-0">{{ orderNumber }}. {{question.question}}</h6>
    <hr class="mt-2 mb-4" />

    @if (question.type == 'CHECKBOX') {
        @if (formGroup.touched && formGroup.errors?.['required']) {
            <div class="alert alert-warning" role="alert">
                At least one option must be selected
            </div>
        }
        <div [formGroup]="formGroup" *ngIf="!disabled">
            @for (option of question.options; track option) {
                <div class="form-check form-switch mb-2">
                    <input class="form-check-input" (change)="checkboxSelected(option.id)" formControlName="option{{option.id}}" type="checkbox" name="question{{question.id}}" role="switch" id="option{{option.id}}" />
                    <label class="form-check-label" for="option{{option.id}}">{{ option.name }}</label>
                </div>
            }
        </div>
        <div class="mb-2" style="opacity:.5" *ngIf="disabled">
            @for (option of question.options; track option) {
                <p>
                    @if (question.answer == option.id || question.answer?.includes(option.id.toString()) || question.answer?.includes(option.id)) {
                        <i class="bi bi-check-square-fill"></i>
                    } @else {
                        <i class="bi bi-square"></i>
                    }
                    {{ option.name }}
                </p>
            }
        </div>
    }

    @if (question.type == 'RADIO') {
        <div [formGroup]="formGroup" *ngIf="!disabled">
            @for (option of question.options; track option) {
                <div class="form-check mb-2">
                    <input class="form-check-input" [formControl]="formControl" type="radio" (change)="onValueChange()" name="question{{question.id}}" value="{{option.id}}" id="option{{option.id}}" />
                    <label class="form-check-label" for="option{{option.id}}">{{ option.name }}</label>
                </div>
            }
        </div>
        <div class="mb-2" style="opacity:.5" *ngIf="disabled">
            @for (option of question.options; track option) {
                <p>
                    @if (question.answer == option.id) {
                        <i class="bi bi-record-circle-fill"></i>
                    } @else {
                        <i class="bi bi-circle"></i>
                    }
                    {{ option.name }}
                </p>
            }
        </div>
    }

    @if (question.type == 'RADIO_IMAGE') {
        @if (formControl.touched && formControl.errors?.['required']) {
            <div class="alert alert-warning" role="alert">
                At least one option must be selected
            </div>
        }
        <div class="row">
            <input class="form-check-input" [formControl]="formControl" type="hidden" name="question{{question.id}}" value="{{question.answer}}" />
            @for (option of question.options; track option) {
                <div class="col-auto">
                    <button type="button" [disabled]="disabled" class="btn px-0 border-4 {{formControl.value == option.id?'check-selected-color':'border-white'}}" (click)="formControl.setValue(option.id)">
                        <img src="{{option.name}}">
                    </button>
                </div>
            }
        </div>
    }

    @if (question.type == 'TEXT') {
        <input class="form-control" (change)="onValueChange()" [formControl]="formControl" type="text" name="question{{question.id}}" id="question{{question.id}}" />
    }

    @if (question.type == 'TEXTAREA') {
        <textarea class="form-control" [formControl]="formControl" name="question{{question.id}}" id="question{{question.id}}"></textarea>
    }

</div>
